@import 'variable.scss';

.bookingHead {
  font-family: $font-family;
  padding: 6px 7px;
  margin-bottom: 9px;
  color: white;
  background-color: $addexportActionButtonBGColor;
  border-radius: 0px 5px 5px 0px;
  font-size: 14px;
}

.export:hover .iconAddbooking,
.export:hover .bookingHead {
  background: $addexportActionButtonBGHoverColor;
  opacity: 100%;
}

.dropdownMenu:hover .iconAddbooking,
.dropdownMenu:hover .bookingHead {
  background: $addexportActionButtonBGHoverColor;
  opacity: 100%;
}

.dropdown:hover .iconAddbooking, .dropdown:hover .bookingHead{
  background-color:$addexportActionButtonBGHoverColor;
  opacity: 100%;
}

.btn-camera {
  background-color: $addexportActionButtonBGColor;
  color: $secondaryFontColor;
  border: none;
  padding: 5px;
}

.btn-camera:hover {
  background-color: $addexportActionButtonBGHoverColor;
  color: $secondaryFontColor;
}

.btn-success {
  background-color: $successButtonBackgroundColor;
}

.btn-submit-allotment{
  @extend .btn-success;
  height: 34px;
  padding-top: 6px !important;
  font-size: 14px;
  color: $secondaryFontColor;
}

.btn-danger {
  background-color: $cancelButtonBackgroundColor;
}

.btn-approve{
  background-color: $approvalActionButtonColor;
  color: $secondaryFontColor;
  font-size: 14px;
  padding-top: 6px !important;
  height: 34px;
}
/*#region Switch Button CSS*/
.selectedTab{
  background: $primaryBackgroundColor;
  padding: 7px 10px;
  border-radius: 5px;
  color: $secondaryFontColor;
  font-size: $baseFontSize;
  cursor: pointer;
  border: 1px solid $primaryBackgroundColor;
  text-align: center;
  font-weight: 600;
}

.selectedTab:hover{
  cursor: pointer;
  background: $primaryBackgroundColor;
  color: $secondaryFontColor;
}

.export-div{
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  position: absolute;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-top: var(--bs-gutter-y);
}

.subHeaderButtonDiv{
  position: relative;
}

.document-editbutton {
  text-align: right;
 // margin-right: 8px;
}

.document-edit-div{
  margin: 4px 1px 5px 1px;
}

.defaultTab{
  text-align: center;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid $sectionHeaderBackgroundColor;
  font-weight: 600;
}

.defaultTab:hover{
  @extend .defaultTab;
  cursor: pointer;
  background-color: $sectionHeaderBackgroundColor;
  color: $primaryFontColor;
}

/*#endregion */

@media only screen and (max-width: 900px) and (min-width: 340px) {
  .iconAddbooking {
    background-color: $addexportActionIconButtonBGColor;
    padding: 6px;
    margin-left: 6px;
    color: $secondaryFontColor;
    border-radius: 5px 0px 0px 5px;
  }

  .bookingHead {
    font-family: $font-family;
    padding: 7px 5px 6px 4px;
    color: $secondaryFontColor;
    background-color: $addexportActionButtonBGColor;
    border-radius: 0px 5px 5px 0px;
    font-size: 14px;
  }
  .iconAdd {
    background-color: $addexportActionIconButtonBGColor;
    color: $secondaryFontColor;
    border-radius: 5px 0px 0px 5px;
    height: 35px;
    display: inline-block;
    padding: 0px 5px;
    line-height: 35px;
  }

  .Addbutton {
    font-family: Arial, Helvetica, Arial;
    color: $secondaryFontColor;
    background-color: $addexportActionButtonBGColor;
    border-radius: 0px 5px 5px 0px;
    font-size: 14px;
    display: inline-block;
    height: 35px;
    padding: 0px 10px;
    line-height: 35px;
    font-weight: normal !important;
}
  }
//For mobile view button click
  .positioned-flex {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    z-index: 10;
}

//For export to excel button


ul li {
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
}

.dropdownMenu-content {
  min-width: 120px !important;
}

.dropdownMenu i:hover {
  color: $primaryBackgroundColor;
}

.dropdownMenu:hover .dropdownMenu-content {
  display: block;
}
.dropdown-toggle{
  text-align: center;
}

/*#region Icon Region CSS*/
.userIcon{
  height: 20px;
    width: 20px;
    padding: 2px;
    border-radius: 50%;
}

/*#endregion */
