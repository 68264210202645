$primaryBackgroundColor: #BA141A;
$secondaryBackgroundColor: #ffffff;
$font-family:  Arial, Helvetica, Arial Bold;
$primaryFontColor: #000000;
$secondaryFontColor: #ffffff;
$successButtonBackgroundColor: #00990F;
$successButtonBorderColor : #00990F;
$cancelButtonBackgroundColor: #FF0000;
$cancelButtonBorderColor:#FF0000;
$baseFontSize: 16px;
$primarySuccessBtn:#00990F;
$sectionHeaderBackgroundColor:#CDCDCD;
$sectionBodyBackgroundColor:#F2F2F2;

$addexportActionIconButtonBGColor:#01AC92;
$addexportActionButtonBGColor: #01C8AB;
$addexportActionButtonBGHoverColor:#407c73;

$formHeaderColor : #dee8e03d;

$approvalActionButtonColor : #0067c7;

$tableHeader :#f7f7f7;
:root {
    --primaryBackgroundColor: #BA141A;
    --secondaryFontColor: #ffffff;
    --allotedUnitBackGround: #98FB98;
    --nonAllotedUnitBackground : #FF2400;
  }

