/* You can add global styles to this file, and also import other style files */
@import "@ng-select/ng-select/themes/default.theme.css";
// ... or
@import "@ng-select/ng-select/themes/material.theme.css";
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'ngx-toastr/toastr';
@import 'primeicons/primeicons.css';
@import "ngx-bootstrap/datepicker/bs-datepicker.css";
@import './scss/variable.scss';
@import './scss/button.scss';
// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
// boostrap 5
@import 'ngx-toastr/toastr-bs5-alert';
//primeNg
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import './scss/primeng-custom-styles.scss';
@import './scss/variable.scss';

//#region generic css implemented globally
html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0 !important;
    font-family: $font-family;
}

.container-fluid {
    padding-left: 100px;
}

.container-fluid.active {
    padding-left: 0px;
}

.text-center {
    text-align: center !important;
}
//#endregion

//#region toaster(global)
.toast-message{
    color: white !important;
}
//#endregion

//#region list screen css(global)
.listHeaderText{
    font-size: $baseFontSize + 8px;
}
//#endregion

//#region card css means list css of mobile view(global)
.row-cards-one {
    margin: 0px 5px;
}

.row-cards-one .mycard {
    border-radius: 3px;
    border-left: 10px $primaryBackgroundColor solid;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.30) !important;
    padding: 25px 40px 30px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.row-cards-one .mycard .left {
    position: inherit;
    z-index: 9;
}

.row-cards-one .mycard .right {
    position: inherit;
    z-index: 9;
}

.row-cards-one .mycard .left .title {
    font-size: 22px;
    color: $secondaryBackgroundColor;
    line-height: 32px;
    margin-bottom: 2px;
}

.row-cards-one .mycard .left .number {
    font-size: 30px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    color: $secondaryBackgroundColor;
    margin-bottom: 20px;
}

.row-cards-one .mycard .left .link {
    width: 80px;
    height: 30px;
    background: $secondaryBackgroundColor;
    border-radius: 50px;
    border-color: red !important;
    line-height: 30px;
    font-size: 14px;
    display: block;
    text-align: center;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.row-cards-one .mycard .right .icon {
    font-size: 80px;
    color: $secondaryBackgroundColor;
    position: inherit;
    z-index: 9;
}

.row-cards-one .mycard::after {
    position: absolute;
    content: " ";
    width: 268px;
    height: 500px;
    top: -100px;
    right: -50px;
    -webkit-transform: rotate(28deg);
    -ms-transform: rotate(28deg);
    transform: rotate(28deg);
}

.row-cards-one .mycard.bg1 {
    background-image: -webkit-gradient(linear, left top, right top, from($secondaryBackgroundColor), to($secondaryBackgroundColor));
    background-image: -webkit-linear-gradient(left, $secondaryBackgroundColor, $secondaryBackgroundColor);
    background-image: -o-linear-gradient(left, $secondaryBackgroundColor, $secondaryBackgroundColor);
    background-image: linear-gradient(to right, $secondaryBackgroundColor, $secondaryBackgroundColor);
    border-radius: 20px;
    overflow: visible;
}
//#endregion

//#region ng-select css(global)
.ng-select.ng-select-single .ng-select-container {
    height: 28px;
    border-radius: 10px;
}

.ng-dropdown-panel.ng-select-bottom {
    margin-top: 18px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: $secondaryBackgroundColor;
    color: $primaryBackgroundColor !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    color: $secondaryBackgroundColor !important;
    background-color: $primaryBackgroundColor !important;
}

.ng-dropdown-panel .scroll-host {
    border-radius: 2px;
}

.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: flex-end;
    bottom: 7px;
    display: none;
}
.ng-select .ng-select-container:after {
    border-bottom: thin solid rgba(0, 0, 0, 0);
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

.ng-select .ng-select-container {
    cursor: default;
    display: flex;
    outline: none;
    overflow: hidden;
    width: 99%;
    color: $primaryFontColor;
    align-items: baseline;
    min-height: 42.5px;
}

.ng-dropdown-panel .scroll-host {
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    display: block;
    -webkit-overflow-scrolling: touch;
}

.ng-select {
    padding-bottom: 0em;
}

//input value css where ng input is used
.ng-value {
    margin: -10px 0px 0px 9px;
}

//placeholder css where ng-select dropdown is used
.ng-placeholder {
    margin: -12px 5px 10px 13px;
}
//#endregion

@media (min-width: 992px) {
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}



