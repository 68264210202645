@import './variable.scss';

//#region start sidebar css
.p-sidebar-header {
    color: $secondaryFontColor;
    background-color: $primaryBackgroundColor;
    font-size: large;
    display: flex;
    justify-content: space-between;

    button {
        border-radius: 2rem;
        float: right;
    }

    button:hover{
        background-color: $secondaryBackgroundColor;
        color: $primaryBackgroundColor
    };
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled {
    color: $secondaryFontColor;
    font-weight: 600;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: $primaryBackgroundColor;
    font-weight: 600;
}

.p-datatable{
    border: 1px solid $sectionHeaderBackgroundColor;
    border-radius: 2px;
}

.p-datatable-thead{
    border-bottom: 1px solid $sectionHeaderBackgroundColor;
}

p-table tr th,
p-table tr td{
    // width: 8%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    text-align: center;
    font-size: 2.25vh;
    color: $primaryFontColor;
}

//Set Pdata table header style
p-table th{
    background-color: $secondaryBackgroundColor;
    padding-top: 10px;
    padding-bottom: 10px;
}

.table-checkbox-cell
{
    width: 2%;
    text-align: left;
}

p-table tr:nth-child(even){
    // background-color: rgb(238, 234, 234);
    background-color: $secondaryBackgroundColor;
    border-top: 1px solid $sectionHeaderBackgroundColor;
    border-bottom: 1px solid $sectionHeaderBackgroundColor;
}

.p-datatable .p-datatable-tbody > tr > td{
    padding: 0.50rem;
}
//background colour of table row will change on hover
p-table tr:hover{
    background-color: $secondaryBackgroundColor;
}


//action cell of table (normally in last column of table
.table-action-cell{
    text-align: center;
}

p-columnFilter{
    float: right;
}


::ng-deep p-menu{
    z-index:1001;
    position: relative;
}

/*#endregion Prime NG Data Table*/


// #region Prime NG Calendar
    // #region CHANGE SIZE OF CALENDAR
    ::ng-deep .p-datepicker table td {
        padding: 0.2rem !important;
    }

    ::ng-deep .p-datepicker table td > span {
        width: 1.7rem;
        height: 1.7rem;
    }

    ::ng-deep .p-datepicker table {
        font-size: 10pt;
        margin: 0.25rem 0;
        text-align: center;
    }

    ::ng-deep .p-datepicker .p-datepicker-header {
        padding: 0.2rem;
    }

    p-calendar input[type =text]{
       height: 39px;;
       width: 100%;
    }

    //calendar control icon css
    .p-calendar-w-btn .p-datepicker-trigger {
        height: 39px;
        background-color: $primaryBackgroundColor;
        color: $secondaryFontColor;
        border-radius: 0px 10px 10px 0px;
        border: 1px solid $primaryBackgroundColor;
    }

    .p-calendar-w-btn .p-datepicker-trigger:hover {
        // background-color: $secondaryBackgroundColor;
        color: $secondaryFontColor;
    }
    // #endregion


// #region TIME PICKER
.p-timepicker button{
    border-radius: 1rem;
}

.p-timepicker button:hover{
    background-color: $secondaryBackgroundColor;
    border-radius: 1rem;
}
// #endregion

    // #region CALENDAR HEADER
    //Setting previous arrow button in header margin from left
    .p-datepicker-prev{
        margin-left: 5px;
    }

    //Setting next arrow button in header margin from right
    .p-datepicker-next{
        margin-right: 5px;
    }

    .p-datepicker .p-datepicker-header {
    background-color: $primaryBackgroundColor;
    color: $secondaryBackgroundColor;
        button {
            color: $secondaryFontColor;
            border-radius: 1rem;
        }

        button:hover {
            background-color: $secondaryBackgroundColor;
            color: $primaryBackgroundColor;
        }
    }
    // #endregion

    // #region CALENDAR BODY

    //Change current date background color
    .p-datepicker-today span{
        background-color: $primaryBackgroundColor;
        color: $secondaryFontColor;
        font-weight: bold;
    }

    //selected or current date view
    .p-datepicker table td > span.p-highlight{
        background-color: $primaryBackgroundColor;
        color: $secondaryFontColor;
    }
    // #endregion
// #endregion

/*#region dialog css*/
.p-dialog .p-dialog-header {
    background-color: $primaryBackgroundColor;
    height: 45px;
    color: $secondaryFontColor;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
    border: none;
    background-color: $primaryBackgroundColor;
    color: $secondaryFontColor;
    margin-right: 5px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child:hover {
    border-radius: 5px;
    background-color: $primaryBackgroundColor;

}

.p-dialog {
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    max-height: 90%;
    transform: scale(1);
    position: relative;
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border: 0 none;
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-content {
    background: $secondaryBackgroundColor;
    color: $formHeaderColor;
    padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog-content {
    overflow-y: auto;
    flex-grow: 1;
}
.p-component .p-overlay ol, ul {
    padding-left: 0px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {

    background: $primaryBackgroundColor;
    text-shadow: 1px 1px 1px $sectionHeaderBackgroundColor;
    color: $secondaryFontColor;
}
.p-dropdown-items-wrapper{
    overflow: initial !important;
}
.custom-calendar .p-inputtext {
    border-top-left-radius: 10px; /* Top-left corner */
    border-bottom-left-radius: 10px; /* Bottom-left corner */
    border-top-right-radius: 0; /* Ensure right corners remain unchanged */
    border-bottom-right-radius: 0;
  }

@media only screen and (max-width: 800px) and (min-width: 340px) {

    .p-dialog {
        max-width: 100%;
    }

    .p-element {
        max-width: 90%;
    }

    .listHeaderText{
        font-size: $baseFontSize + 5px;
    }

}

/*#endregion */